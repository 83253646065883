import React from 'react';
import Layout from '../components/layout/Layout';
import GenericPageTopHeader from '../components/GenericPageTopHeader';

const ContactThankYouPage = () => {
  return (
    <Layout>
      <GenericPageTopHeader pageTitle='Contact' className='container mx-auto my-4 text-4xl' />
      <section className="container mx-auto">
        <div>
          Thank you for you interest, our team will be in touch we you shortly.
        </div>
      </section>
    </Layout>
  );
}

export default ContactThankYouPage;
