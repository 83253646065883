import React from 'react';
import TabTitle from '../components/meta/tabTitle';

export const GenericPageTopHeader = ({pageTitle, bgColorClass, subContent, customTextClass}) => {

  // #bgHeaderColor in the frontmatter
  const colorPalletGenerator = (bgC) => (
    {
      'headerdarkpurple': 'bg-headerdarkpurple',
      'headerdarkgreen': 'bg-headerdarkgreen',
      'headerred': 'bg-headerred',
      'headergrey': 'bg-headergrey',
      'splashblue': 'bg-splashblue',
    }[bgC] || 'bg-headerdarkpurple'
  );

  const bgColor = colorPalletGenerator(bgColorClass)

  return (
    <section className={`pb-8 pt-8 mb-8 genetic-page-top-header ${bgColor}`}>
      <TabTitle title={pageTitle} />
      <div className="container mx-auto sm:flex">
        <h1 className={`sm:text-center font-light text-white font-semibold text-4xl mb-2 ${customTextClass}`}>
          {pageTitle}
        </h1>
      </div>
      { subContent && <div
        dangerouslySetInnerHTML={{ __html: subContent }}
        className="markdown container mx-auto text-white"
      />}
    </section>
  );
}

export default GenericPageTopHeader;
